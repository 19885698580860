import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from '../../components/Table/DataTable'
import { CustomThemeProvider } from '../../theme/CustomThemeProvider'
import { /*colors,*/ Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";
import { ButtonsProps, Column } from '../../components/Table/types';
import useAsync from '../../hooks/useAsync'
import { deleteUser, getUsersByAppAndCustomer, inactivate } from '../../actions/user.action';
import { validateSessionStatus } from '../../actions/auth.action';
import FullLoader from '../../components/Loader/FullLoader.component'
import NewUserIcon from '../../components/IconsLibrary/NewUserIcon';
import useConfirmationDialog from '../../hooks/useAlertConfirm'
import { ACTION_DELETE_USER, ACTION_INACTIVATE_USER } from '../../const/constants';
import { UserForm } from './UserForm';
import { parseJwt } from '../../utils/utils';
import ViewLayout from '../../components/Layouts/ViewLayout';
import MassiveLoadComponent from '../../components/MassiveLoad/MassiveLoadComponent';
import MassiveLoadInfo from '../../components/MassiveLoad/MassiveLoadInfo';
import MassiveLoadResult from '../../components/MassiveLoad/MassiveLoadResult';
import useLoadMassiveUsers from "../../hooks/useLoadMassiveUsers";
import { LoadModal } from "../../components/modal/LoadModal";
import './types';
const useStyles = makeStyles({
  styleFab: {
    boxShadow: "none",
  },
});

const AdminUsersComponent = ({ token, appName, useSharedRoles, rolesByFilter,color }: AdminUsersProps) => {
  const [option, setOption] = useState(null);
  const [isFormShowed, setFormShowed] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [changeOption, setChangeOption] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  type IdUser = {
    id: string,
    idstate: any
  }
  const [idInactive, setIdInactive] = useState<IdUser>({
    id: "",
    idstate: false
  });

  const [idDelete, setIdDelete] = useState({ id: "" });
  const [currentPrivileges, setCurrentPrivileges] = useState<CurrentClaims>();
  const customerInfo = useRef<CustomerInfo>()
  const [user, setUser] = useState<userProps>(
    {
      id: "",
      document: "",
      userName: "",
      names: "",
      surnames: "",
      email: "",
      phoneNumber: "",
      idbusinessunit: 0,
      accessLevelId: 2
    }
  );
  const [rows, setRows] = useState([]);
  const [lastInfo, setLastInfo] = useState(0);
  const [search, setSearch] = useState<string | undefined>('');
  const {
    onUpload,
    onDownload,
    onSucess,
    imageModal,
    titleModal,
    messageModal,
    modal,
    itemsList,
    resultValidateLoadMassiveUser,
    handleChangeTab,
    tab,
    handleBack,
    confirmText,
    cancelText,
    cancel,
    confirmate,
    onCancel,
    onContinue,
    setTab,
    setIsMassiveLoad,
    isMassiveLoad,
    onSave,
    resultLoadMassiveUser,
    onDownloadErrors,
    btnActive,
    messageLoadModal,
    flagMessageLoad,
  } = useLoadMassiveUsers();
  const [dataAlertConfirm, setDataAlertConfirm] = useState<AlertConfirmProps>({
    message: "",
  });
  const [messageDialogData, setMessageDialogData] = useState("")

  const {
    result,
    execute,
    loading
  } = useAsync({
    asyncFunction: getUsersByAppAndCustomer
  })

  const {
    result: resultInactivate,
    execute: executeInactivate,
    loading: loadingInactivate
  } = useAsync({
    asyncFunction: inactivate
  })

  const {
    result: resultdeleteUser,
    execute: executedeleteUser,
    loading: loadingdeleteUser
  } = useAsync({
    asyncFunction: deleteUser
  })

  const { 
    result: resultValidateSessionUser,
    execute: executeValidateSessionUser
  } = useAsync({
    asyncFunction: validateSessionStatus
  })

  const { Dialog, onOpen, onClose } = useConfirmationDialog({
    bodyText: dataAlertConfirm.message,
    cancelButtonText: 'No',
    confirmationButtonText: 'Si',
    onConfirmClick: () => handleAcceptAlert(),
    onCancelClick: () => {
      setDataAlertConfirm({ message: "", })
      onClose()
    }
  })

  const { Dialog: MessageDialog, onOpen: messageDialogOpen } = useConfirmationDialog({
    bodyText: messageDialogData,
  })

  const handleAcceptAlert = () => {
    if (dataAlertConfirm.action !== undefined && dataAlertConfirm.data !== undefined) {
      if (dataAlertConfirm.action === ACTION_DELETE_USER) {
        executeValidateSessionUser(token);
        setIdDelete({ 
          id: dataAlertConfirm.data.id
        });
        setChangeOption(2);
      }
      if (dataAlertConfirm.action === ACTION_INACTIVATE_USER) {
        executeValidateSessionUser(token);
        setIdInactive({ 
          id: dataAlertConfirm.data.id,
          idstate: dataAlertConfirm.data.idstate
        });
        setChangeOption(3);
      }
    }
    onClose()
    setDataAlertConfirm({ message: "", })
  }

  const exceuteGetAllUsers = () => {
    executeValidateSessionUser(token);
    setSearch('');
    setLastInfo(0);
    setChangeOption(1);
  }

  useEffect(() => {
    if (resultValidateSessionUser !== undefined){
      if (resultValidateSessionUser.statusCode == 200){
        if (changeOption === 1) {
          if (token) {
            execute({
              token,
              appName,
              lastInfo,
              search,
            })
          }
        }
        if (changeOption === 2) {
          executedeleteUser({
            token,
            id: idDelete.id
          })
        }
        if (changeOption === 3) {
          executeInactivate({
            token,
            id: idInactive.id,
            idstate: idInactive.idstate
          })
        }
        if (changeOption === 4) {
          execute({
            token,
            appName,
            lastInfo,
            search,
          });
        }
        if (changeOption === 5) {
          execute({
            token,
            appName,
            lastInfo,
            search: search,
          });
          setSearch('');
        }
      } else {
        setInterval(() => { window.location.reload(); }, 5000);
      }
    }
  }, [resultValidateSessionUser]);

  useEffect(() => {
    if (token !== "Bearer " && token !== "") {
      const userResult = parseJwt(token);
      const currentApplication = userResult.APPS;
      const keyPrivilege = `${currentApplication}_PRIVILEGE`;
      setCurrentPrivileges({ app: currentApplication.toUpperCase(), privileges: userResult[keyPrivilege] });
      customerInfo.current = {
        customerTypeDocument: userResult.customer_type,
        customerDocumentNumber: userResult.customer_value,
        Name: userResult.customer_name
      }
      exceuteGetAllUsers()
    }
  }, [token])

  useEffect(() => {
    if (result !== undefined) {
      setData(result.message);
      setRows(result.rows);
      setIsSearch(result.isSearch);
    }
  }, [result])

  useEffect(() => {
    if (resultInactivate !== undefined) {
      setMessageDialogData(resultInactivate.message.information)
      exceuteGetAllUsers()
      messageDialogOpen()
    }
  }, [resultInactivate])

  useEffect(() => {
    if (resultdeleteUser !== undefined) {
      if(resultdeleteUser.statusCode == 200){
        exceuteGetAllUsers()
      }
      setMessageDialogData(resultdeleteUser.message)
      messageDialogOpen()
    }
  }, [resultdeleteUser])

  useEffect(() => {
    if (resultLoadMassiveUser !== undefined) {
      if(resultLoadMassiveUser.statusCode == 200){
        onSucess(true, resultLoadMassiveUser.message.information);
        //setMessageDialogData(resultLoadMassiveUser.message.information)        
        setIsMassiveLoad(false)
        exceuteGetAllUsers()
      }
      else {
        onSucess(false, resultLoadMassiveUser.message);
        //setMessageDialogData(resultLoadMassiveUser.message)
      }

      //messageDialogOpen()
    }
  }, [resultLoadMassiveUser]);

  const columns: Column[] = [
    {
      id: "userName",
      label: "Nombre de usuario",
      haveDropDown: true,
      align: "center",
    },
    {
      id: "names",
      label: "Nombres",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "surnames",
      label: "Apellidos",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "document",
      label: "Documento\u00a0",
      minWidth: 100,
      align: "center",
      haveDropDown: true,
      format: (value: any) =>
        !isNaN(value) ? parseFloat(value).toLocaleString("es-CO") : value,
    },
    {
      id: "email",
      label: "Correo\u00a0electrónico",
      minWidth: 170,
      align: "center",
      haveDropDown: true,
    },
    {
      id: "idstate",
      label: "Estado",
      minWidth: 90,
      align: "center",
      haveDropDown: true,
      isVisible: (row: any) => {
        if (row.idTypeUser === 2) {
          return true;
        } else {
          return false;
        }
      },
      component: {
        handleChange: (e: any, id: any) => handleConfirmClick(e, id),
      },
      handleIconClick: (id: any) => handleDeleteClick(id),
      withIcon: currentPrivileges?.privileges.includes(`${currentPrivileges.app}_USER_DELETE`) ? true : false,
      tooltipMessage: "usuario",
      format: (value: any) => (value === 1 ? false : true),
    },
  ];

  const getButtons = () => {
    let button: ButtonsProps = {
      searchBar: {
        cellSize: {
          xs: 12,
          sm: 12,
          md: 4,
          lg: 5,
        },
      },
      menuOrder: {
        justify: "center",
        options: [
          {
            label: "Activo",
            columns: [
              {
                prop: "idstate",
                direction: 1,
              },
            ],
          },
          {
            label: "Inactivo",
            columns: [
              {
                prop: "idstate",
                direction: -1,
              },
            ],
          },
          {
            label: "Más reciente",
            columns: [
              {
                prop: "modifiedon",
                direction: 1,
                format: (date: any) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
          {
            label: "Más antiguo",
            columns: [
              {
                prop: "modifiedon",
                direction: -1,
                format: (date: any) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
        ],
        cellSize: {
          xs: 12,
          sm: 12,
          md: 2,
          lg: 2,
        },
      },
      justify: "flex-start",
      chargeButton: {
        show: checkAuthLoadMassive(),
        cellSize: {
          xs: 12,
          sm: 12,
          md: 2,
          lg: 2,
        },
      },
      children: () => {
        return (
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={2}
            xs={2}
            direction="row-reverse"
          >
            {
              currentPrivileges?.privileges.includes(`${currentPrivileges.app}_USER_CREATE`) && (
                <Tooltip title={`Crear usuario`} aria-label={`Nuevo usuario`}>
                  <Fab
                    className={classes.styleFab}
                    size="small"
                    onClick={() => {
                      setFormShowed(true)
                    }}
                  >
                    <NewUserIcon personalizationcolor={color}/>
                  </Fab>
                </Tooltip>
              )
            }

          </Grid>
        );
      },
    };

    return button;
  };

  const checkAuthLoadMassive = () => {
    if (currentPrivileges?.privileges.includes(`${currentPrivileges.app}_USER_CREATE`)) {      
      return true;      
    }
    return false;
  };

  const handleCellClick = (row: any) => {
    if (currentPrivileges?.privileges.includes(`${currentPrivileges.app}_USER_MODIFY`)) {
      setUser({
        id: row.id,
        names: row.names,
        surnames: row.surnames,
        email: row.email,
        document: row.document,
        userName: row.userName,
        phoneNumber: row.phoneNumber === "0" ? "" : row.phoneNumber,
        idbusinessunit: row.idbusinessunit,
        accessLevelId: row.accessLevelId
      })
      setFormShowed(true)
    } else {
      setMessageDialogData("No tienes permisos para modificar usuarios")
      messageDialogOpen()
    }
  };

  const handleOnLoadButton = () => {
    setIsMassiveLoad(true);
    setTab(0);
  };

  const handleConfirmClick = (e: any, id: any) => {
    if (currentPrivileges?.privileges.includes(`${currentPrivileges.app}_USER_STATE`)) {
      setDataAlertConfirm({
        message: `¿Deseas ${e.target.checked ? "activar" : "desactivar"
          } este usuario?`,
        action: ACTION_INACTIVATE_USER,
        data: {
          id: id,
          idstate: e.target.checked
        }
      })
      onOpen()
    } else {
      setMessageDialogData("No tienes permisos para activar ni desactivar usuarios")
      messageDialogOpen()
    }

  };

  const handleDeleteClick = (id: any) => {
    setDataAlertConfirm({
      message: "¿Deseas eliminar este usuario?",
      action: ACTION_DELETE_USER,
      data: {
        id: id,
      }
    })
    onOpen()
  };

  const onSubmit = () => {
    const userResult = parseJwt(token);
    const customerInfoData = {
      DocumentType: userResult.customer_type,
      DocumentNumber: userResult.customer_value,
      Name: userResult.customer_name
    }
    onSave(token, customerInfoData);
  };

  const onDownloadMassive = () => {
    onDownload(token);
  };

  const onUploadMassive = (e: any) => {
    onUpload(e, token);
  };

  const handleChangePages = (_event: any, newPage: any, rowsPerPage: any) => {
    const numRows = rowsPerPage;
    let last;
    last = data.length;
    setLastInfo(last);
    if (!isSearch && (last < (newPage + 1) * numRows)) {
      executeValidateSessionUser(token);
      setChangeOption(4);
    }
  };

  const handleSearchUsers = (name: any) => {
    setSearch(name);
    executeValidateSessionUser(token);
    setChangeOption(5);
    setLastInfo(0);
  };

  return (
    <div>
      <LoadModal
        title={titleModal}
        open={modal}
        icon={<img src={imageModal} alt="hands" className={"imageInfo"} style={{height: 80, width: 92, margin: 10}} />}
        message={messageModal}
        handleConfirm={onContinue}
        isCancel={cancel}
        handleCancel={onCancel}
        isConfirm={confirmate}
        confirmText={confirmText}
        cancelText={cancelText}
        messageLoadModal={messageLoadModal}
        flagMessageLoad={flagMessageLoad}
        personalizationColor={color}
      />
      <ViewLayout headerTitle='Administrar usuarios' personalizationColor={color}>
        {
          !isMassiveLoad? (
            isFormShowed
            ? (
                <UserForm
                  user={user}
                  setUser={setUser}
                  setCardState={setFormShowed}
                  token={token}
                  appName={appName}
                  customerInfo={customerInfo.current}
                  exceuteGetAllUsers={exceuteGetAllUsers}
                  useSharedRoles={useSharedRoles}
                  rolesByFilter={rolesByFilter}
                  currentPrivileges={currentPrivileges}
                  personalizationColor={color}
                />
              )
            : (
              <div>
                <DataTable
                  columns={columns}
                  data={data}
                  paramToDisable={"idstate"}
                  havePagination={data.length > 10 ? true : false}
                  globalDisabled={false}
                  validateToDisable={true}
                  buttons={getButtons()}
                  option={option}
                  setOption={setOption}
                  handleCellClick={handleCellClick}
                  handleOnLoadButton={handleOnLoadButton}
                  personalizationColor={color}
                  rows={rows}
                  handleChangePages={handleChangePages}
                  handleSearchUsers={handleSearchUsers}
                />
                <FullLoader open={loading || loadingInactivate || loadingdeleteUser} viewLoader={true} />
                <Dialog />
                <MessageDialog />              
              </div>
            )
          ) : (
            <MassiveLoadComponent
              loadResult={
                <MassiveLoadResult
                  sucessfull={resultValidateLoadMassiveUser?.message.exitosos}
                  items={itemsList}
                  replaced={resultValidateLoadMassiveUser?.message.reemplazados}
                  error={resultValidateLoadMassiveUser?.message.errores}
                  onDownloadErrors={onDownloadErrors}
                  onContinue={onSubmit}
                  btnActive={btnActive}
                  personalizationColor={color}
                />
              }
              loadInfo={
                <MassiveLoadInfo
                  onDownload={onDownloadMassive}
                  onUpload={onUploadMassive}
                  title={"Bienvenido, así podrás hacer el cargue de usuarios"}
                  personalizationColor={color}
                />
              }
              title={"Cargue masivo de usuarios"}
              handleBack={handleBack}
              handleChangeTab={handleChangeTab}
              tab={tab}
              personalizationColor={color}
            />
          )
        }
      </ViewLayout>
    </div>
  )
}

export const AdminUsers = (props: AdminUsersProps) => {
  return (
    <CustomThemeProvider>
      <AdminUsersComponent {...props} />
    </CustomThemeProvider>
  )
}
