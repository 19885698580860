export enum ECompanyActionTypes {
  CREATE_BASIC_DATA = 'CREATE_BASIC_DATA',
  CREATE_PARAMETRIZATION = 'CREATE_PARAMETRIZATION',
  CREATE_PERSONALIZATION = 'CREATE_PERSONALIZATION'
}

export interface ICompanyBaseAction {
  type: ECompanyActionTypes
}

export interface IBasicData {
  id: string
  name: string
  code?: string
}

export interface CreateParametersConfig {
  companyId: string
  parameters: {}
}

export interface ICreatePersonalizationConfig {
  /* customerId: number
  parameters: {} */
  logo: IImageData
  backgroundImage: IImageData
  backgroundColor: string
  principalColor: string
  secondaryColor: string
  buttonColor: string
  buttonTextColor: string
}

export interface CreateImage {
  Application: 'Biometria'
  BaseContent64: string
  FileExtension: '.jpg' | '.png' | '.ico' | '.jpeg'
  Repository: 2
  Usuario: string
}

export interface DeleteImage {
  location: string
  name: string
  repository: '2'
}

export interface IImageData {
  blob: string
  name: string
  path: string
  extension: string
}

export interface ImageManagerResponse {
  httpStatus: number
  codigoRespuesta: string
  descripcionRespuesta: string
  detalleRespuesta: any
  location: string
  name: string
  repository: '2'
}

export interface ICompanyBasicData {
  personType: number | undefined
  documentType: number | undefined
  documentNumber: string
  verificationDigit: number | undefined
  businessName: string
  additionalInformation: string
  phone: string | undefined
  email: string
  address: string
  department: number | undefined
  city: number | undefined
  personTypeLr: string | undefined
  documentNumberLr: string
  namesLr: string
  surnamesLr: string
  idState: number | undefined
  latitude: string
  longitude: string
  personalizedDesign: false
}

export interface SettingsResponseDto {
  parameters: IParametrization
  information: string
  informationCode: string
}

export interface IUpdateBasicData {
  customerId: number | undefined
  businessName: string
  additionalInformation: string
  phone: string | undefined
  email: string
  address: string
  department: number | undefined
  city: number | undefined
  personTypeLr: string | undefined
  documentNumberLr: string
  namesLr: string
  surnamesLr: string
  idState: number | undefined
  latitude: string
  longitude: string
  personalizedDesign: false
  userEmail: string
}

export interface IBasicDataUser {
  personTypeId?: string | undefined
  documentType?: string
  documentNumber?: string
  dv?: string | undefined
  legalName?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  cellphoneNumber?: string | undefined
  email?: string
  cityId?: number | string | undefined
  fullAddress?: string
  addressAdditionalInformation?: string
  legalDocumentType?: string | undefined
  legalDocumentNumber?: string
  legalFirstName?: string
  legalLastName?: string
}

export interface IStatesAndCities {
  id: number
  stateName: string
  countryIsoCode: string
  idstate: number
  createdby: number
  createdon: string
  modifiedby: number
  modifiedon: string
  idbusinessunit: string
  idowner: number
  idstateNavigation: string
  city: ICities[]
}

export interface ICities {
  id: number
  cityName: string
  stateId: number
  idstate: number
  createdby: number
  createdon: string
  modifiedby: number
  modifiedon: string
  idbusinessunit: string
  idowner: number
  idstateNavigation: string
  customer: string
}

export interface ICompanyBasicDataLicence {
  PaymentMethod: any
  Reference: any
  personTypeId: string
  documentTypeId: number | undefined
  documentTypeCode: string
  documentNumber: string
  dv: string
  legalName: string
  firstName: string
  lastName: string
  cellphoneNumber: string | undefined
  email: string
  fullAddress: string
  department: number | undefined
  cityId: number | string | undefined
  city: string
  legalDocumentTypeId: string | undefined
  legalDocumentNumber: string
  legalFirstName: string
  legalLastName: string
  idstate: number | undefined
  addressLatGeocode: string
  addressLntGeocode: string
  addressAdditionalInformation: string
}

export interface IDocumentType {
  id: number
  documentTypeDesciption: string
  documentTypeCode: string
}

export interface IParametrization {
  encryptedKey: string
  generateATDP: boolean
  repository: string
  templatePathATDP: string
  urlATDP: string
  userStamp: string
  passwordStamp: string
  signATDP: boolean
  signTyC: boolean
  userSign: string
  passwordSign: string
  automaticEmail: boolean
}

export interface PersonalizationResponseDto {
  entityDto: ICreatePersonalizationConfig
  information: string
  informationCode: string
}

export interface jsonColor {
  primaryColor?: string
  secondaryColor?: string
  buttonColor?: string
  textButtonColor?: string
}
